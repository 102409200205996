var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalWrapper',{staticClass:"promotions-history",style:({
        '--no-promotions-text': `'${ _vm.$tc('promotions.noPromotionsDetected') }'`,
    }),attrs:{"data-autotest-id":"history-popup"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('DayChanger',{attrs:{"data-autotest-id":"history-day-changer"}})]},proxy:true},{key:"main",fn:function(){return [_c('div',{staticClass:"promotions-history__layout"},[(!_vm.isClusterPage)?_c('div',{class:{
                    'promotions-history__filter-bar': true,
                    'skeleton-container': _vm.isLoading,
                }},[(!_vm.isLoading)?[_c('CustomSelect',{attrs:{"items":_vm.providerItems,"label":_vm.$tc('titles.provider'),"data-autotest-id":"history-provider-filter"},model:{value:(_vm.providerFilter),callback:function ($$v) {_vm.providerFilter=$$v},expression:"providerFilter"}}),_c('CustomSelect',{attrs:{"items":_vm.programItems,"label":_vm.$tc('titles.program'),"data-autotest-id":"history-program-filter"},model:{value:(_vm.programFilter),callback:function ($$v) {_vm.programFilter=$$v},expression:"programFilter"}})]:[_c('div',{staticClass:"skeleton",staticStyle:{"font-size":"38px","margin-top":"-10px"}},[_vm._v("__________")]),_c('div',{staticClass:"skeleton",staticStyle:{"font-size":"38px","margin-top":"-10px"}},[_vm._v("__________")])]],2):_vm._e(),_c('div',{class:{
                    'promotions-history__graph': true,
                    'promotions-history__graph--empty': _vm.isEmptyGraph,
                }},[_c('transition',{attrs:{"name":"promotions-history__hotel-label-list","mode":"out-in"}},[(!_vm.isDealProgram)?_c('ul',{class:{
                            'promotions-history__hotel-label-list': true,
                            'promotions-history__hotel-label-list--only': _vm.hotelList.length === 1,
                        },attrs:{"data-autotest-id":"history-graph-hotel-list"}},_vm._l((_vm.hotelList),function(hotel){return _c('li',{key:hotel.id,class:{
                                'promotions-history__hotel-label': true,
                                'promotions-history__hotel-label--main': hotel.isMain,
                            },attrs:{"title":hotel.label}},[_vm._v(" "+_vm._s(_vm._f("ClipText")(hotel.label))+" ")])}),0):_vm._e()]),_c('div',{class:{
                        'promotions-history__graph-wrapper': true,
                        'promotions-history__graph-wrapper--hidden-tooltip': _vm.daysAgo === null,
                    }},[_c('CustomGraph',_vm._b({ref:"graph",attrs:{"data-autotest-id":"history-graph"},on:{"setDay":_vm.setDay,"currentDay":_vm.setTooltipDay,"tooltipStateChanged":_vm.setTooltipElement},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('div',{staticClass:"promotions-history__tooltip"})]},proxy:true}])},'CustomGraph',_vm.graphAttrs,false)),_c('PromotionsHistoryTooltip',{attrs:{"data-autotest-id":"history-tooltip"}})],1)],1),_c('div',{staticClass:"promotions-history__metrics-bar"},[_c('div',{staticClass:"promotions-history__date-label"},[_vm._v(" "+_vm._s(_vm.$tc('date'))+" "+_vm._s(_vm.historyDate)+" "),(_vm.scanDate)?_c('span',[_vm._v(" - "+_vm._s(_vm.$tc('titles.scannedAt'))+" "+_vm._s(_vm.scanDate)+" ")]):_vm._e()]),_c('PromotionsHistoryTable',{attrs:{"data-autotest-id":"history-table"},model:{value:(_vm.hiddenGraphs),callback:function ($$v) {_vm.hiddenGraphs=$$v},expression:"hiddenGraphs"}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }